body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navigationBar {
  background-color: lightgreen;
  padding-bottom: 5px;
  width: 100%;
}

.sticky {
  position: sticky;
  top: 0px;
  z-index: 1000;
}

.navigationBar > ul {
  margin-top: 5px;
  min-height: 20px;
  list-style-type: none;
}

.navigationBar > ul > li {
  float: left;
  display: block;
  padding-top: 0px;
  padding-bottom: 0px;
}

.navigationBar > ul > li > a {
  padding-left: 14px;
  padding-right: 14px;
  width: 100%;
}

.navigationBar > ul > li > a:visited {
  background-color: cadetblue;
}

.navigationBar > ul > li > a.active {
  background-color: palegoldenrod;
}

.cardGrid {
  padding: 15px 0px;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cardSelected {
  background-color: hsl(103, 48%, 90%);
  border: 3px solid hsl(82deg 39% 30%);
}

.cardContent {
  flex-grow: 1;
}

.inBetween {
  height: 100%;
  text-align: center;
}

.cardTitle {
  padding-bottom: 15px;
}

.pageTitle {
  text-align: center;
}

.chapterTitle {
  padding-top: 35px;
  padding-bottom: 18px;
  text-align: center;
}

.gridMain {
  padding: 15px;
  margin-top: 30px;
  margin-bottom: 28px;
  justify-content: center;
}

.containerMain {
  padding: 15px;
  margin-top: 30px;
  margin-bottom: 28px;
  justify-content: center;
  text-align: center;
}

.singleRow {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: center;
}

.br {
  content: "";
  display: block;
  margin-top: 40px;
}

.br-big {
  content: "";
  display: block;
  margin-top: 70px;
}

.gapVertical {
  row-gap: 0.5em;
}

.gapVerticalMini {
  row-gap: 1em;
}

.error-tag {
  background: hsl(6 93% 71%); /* red-like */
  border-radius: 4px;
  color: white;
  padding: 4px 8px 4px 8px;
}

.warning-tag {
  background: hsl(39, 95%, 75%); /* orange-like */
  border-radius: 4px;
  color: black;
  padding: 4px 8px 4px 8px;
}

.success-tag {
  background: hsl(90, 75%, 79%); /* green-like */
  border-radius: 4px;
  color: black;
  padding: 4px 8px 4px 8px;
}

.code-editor {
  padding: 5px;
}

.select-like {
  display: inline-block;
  width: 200px;
  border-bottom: solid 1px black;
  margin-top: 8px;
  margin-right: 8px;
  background-color: whitesmoke;
}

.text-field-medium {
  width: 100%; 
  max-width: 400px;
}

.button-medium {
  width: 100%; 
  max-width: 380px; 
  height: 35px;
}

.loose-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.deletion-background {
  /*  #f2cec7 white reddish */
  background: hsl(6 93% 89%);
}

.addition-background {
  /* E5FFED white greenish */
  background: hsl(120 100% 93%);
}

.fresh-templating {
  /* lightish gray */
  background: hsl(38, 10%, 95%)
}

.edited-templating-cell {
  /* less lightish gray */
  background: hsl(38, 10%, 85%)
}

.listish-hover-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  width: 100%;
  &:hover {
    background-color: beige;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
  }
}
